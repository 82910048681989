code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.scroll-to-top {
    background-color: #d41367 !important;
}
